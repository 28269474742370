import "../../styles/pages/home.scss";

import React, { useState } from "react";

import Layout from "../../components/Layout";
import { Link } from "gatsby";
import ResponsiveSource from "../../components/ResponsiveSource";
import SEO from "../../components/SEO";
import WarpedImage from "../../components/WarpedImage";
import classNames from "classnames";

/**
 *
 */
function Home({ pageContext }) {
  const [hoverName, setHoverName] = useState(null);
  const [exitingName, setExitingName] = useState(null);
  const { seo } = pageContext;
  const { title, metaDesc } = seo;
  const homeData = pageContext;

  /**
   * @param e
   * @param name
   */
  function handleClick(e, name) {
    if (!exitingName) {
      e.preventDefault();
      const target = e.currentTarget;
      setExitingName(name);
      setTimeout(() => target.click(), 600);
    }
  }

  /**
   *
   */
  function handleMouseOut() {
    setHoverName(null);
  }

  /**
   * @param name
   */
  function handleMouseOver(name) {
    setHoverName(name);
  }

  const creativeCon = homeData?.homePageTemplate?.creativeContent;
  const capitalCon = homeData?.homePageTemplate?.capitalContent;

  return (
    <Layout
      theme={
        hoverName === "creative"
          ? "light"
          : hoverName === "capital"
          ? "dark"
          : "dark-light"
      }
    >
      <SEO title={title} description={metaDesc} />

      <article
        className={classNames(
          "Home",
          { [`Home--${exitingName}Exiting`]: exitingName },
          { [`Home--${hoverName}Hover`]: hoverName }
        )}
      >
        <div className="Home-half Home-half--capital">
          <Link
            className="Home-halfLink"
            onClick={(e) => handleClick(e, "capital")}
            onMouseOut={handleMouseOut}
            onMouseOver={() => handleMouseOver("capital")}
            to={capitalCon?.pageLink}
          >
            <span className="Home-halfText">{capitalCon?.label}</span>
          </Link>
          <div className="Home-halfBackground">
            <video
              autoPlay
              className="Home-halfBackgroundVideo"
              loop
              muted
              playsInline
            >
              <ResponsiveSource
                sourceDesktop={capitalCon?.videoDesktop}
                sourceMobile={capitalCon?.videoMobile}
                sourceTablet={capitalCon?.videoTablet}
              />
            </video>
          </div>
        </div>
        <div className="Home-half Home-half--creative">
          <Link
            className="Home-halfLink"
            onClick={(e) => handleClick(e, "creative")}
            onMouseOut={handleMouseOut}
            onMouseOver={() => handleMouseOver("creative")}
            to={creativeCon?.pageLink}
          >
            <span className="Home-halfText">{creativeCon?.label}</span>
          </Link>
          <div className="Home-halfBackground">
            <WarpedImage alt="" src={creativeCon?.halfBackground?.sourceUrl} />
          </div>
        </div>
      </article>
    </Layout>
  );
}

export default Home;

// const homeData = useStaticQuery(graphql`
// query {
//   wpPage(isFrontPage: { eq: true }) {
//     id
//   homePageTemplate {
// creativeContent {
//   label
//   halfBackground {
//     sourceUrl
//   }
//   pageLink
// }
// capitalContent {
//   label
//   videoDesktop
//   videoTablet
//   videoMobile
//   pageLink
// }
//   }
// }
// }
// `);
