import React, { useLayoutEffect, useState } from "react";

interface ResponsiveSourceProps {
  sourceDesktop: string;
  sourceTablet: string;
  sourceMobile: string;
}

/**
 * @param props
 * @param props.sourceMobile
 * @param props.sourceTablet
 * @param props.sourceDesktop
 */
function ResponsiveSource({
  sourceMobile,
  sourceTablet,
  sourceDesktop,
}: ResponsiveSourceProps) {
  const [sourceVid, setSourceVid] = useState(sourceDesktop);

  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      setSourceVid(getVideoSrc(window.innerWidth));
    }
  }, []);

  const getVideoSrc = (width) => {
    if (width >= 1280) return sourceDesktop;
    if (width >= 720) return sourceTablet;
    return sourceMobile;
  };

  return <source src={sourceVid} type="video/mp4" />;
}

export default ResponsiveSource;
