import "../styles/components/warped-image.scss";

import React, { useEffect, useRef, useState } from "react";

interface WarpedImageProps {
  alt: string;
  src: string;
}

/**
 * @param props
 * @param props.alt
 * @param props.src
 */
function WarpedImage({ alt, src }: WarpedImageProps) {
  const imageRef = useRef(null);

  const [style, setStyle] = useState<object>({});

  /**
   *
   */
  function handleMouseLeave() {
    setStyle({});
  }

  /**
   * @param e
   */
  function handleMouseMove(e) {
    if (imageRef.current) {
      const mouseCenterX = (window.innerWidth / 2 - e.clientX) * -1;
      const mouseCenterY = window.innerHeight / 2 - e.clientY;
      const imageCenterX = imageRef.current.offsetWidth / 2;
      const imageCenterY = imageRef.current.offsetHeight / 2;
      const x = (mouseCenterY / imageCenterY) * 0.25;
      const y = (mouseCenterX / imageCenterX) * 0.25;
      setStyle({ transform: `rotateX(${x}deg) rotateY(${y}deg)` });
    }
  }

  useEffect(() => {
    document.addEventListener("mouseleave", handleMouseLeave, {
      passive: true,
    });
    document.addEventListener("mousemove", handleMouseMove, { passive: true });
    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="WarpedImage">
      <div className="WarpedImage-inner" style={style}>
        <img alt={alt} className="WarpedImage-image" ref={imageRef} src={src} />
      </div>
    </div>
  );
}

export default WarpedImage;
